<template>
    <div class="sigin">
        <div class="sigin-head">
         <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
      </div>
      <div class="sigin-middle">
          <p>系统正在开发中，敬请期待.....</p>
      </div>
    </div>
</template>

<script>
export default {
    name: 'signIn',
    methods: {
        goHistotry() {
            this.$router.go(-1);
        }
    },
}
</script>

<style scoped>
.sigin {
    height: 100vh;
    background: #fff;
}
.sigin-head{
    height: 44px;
    line-height: 44px;
    background: rgba(0,129,255);
}
.sigin-head .icon-fanhuijiantou {
    font-size: 20px;
    color: #fff;
    padding-left: 10px;
}
.sigin-middle {
    height: calc(100vh - 44px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sigin-middle p {
    font-size: 20px;
}
</style>